// AboutUs.js
import React from "react";
import styles from "./AboutUs.module.css";
import TeamMember from "../../Components/TeamMember";
import DefaultPage from "../../Components/DefaultPage";

import fotoJobao from "./../../assets/images/joao-foto.jpg";
import fotoRombo from "./../../assets/images/romulo-foto.jpg";
import fotoIgu from "./../../assets/images/igor-foto.jpg";
import fotoVittu from "./../../assets/images/vittor-foto.jpg";

function AboutUs() {
    const teamMembers = [
      {
        name: "João Paulo Mendonça Prado",
        role: "Game Design",
        photoUrl: fotoJobao,
        portfolioLink: "https://www.pixeljoao.com.br",
      },
      {
        name: "Igor Morôni",
        role: "Artista 2D/3D",
        photoUrl: fotoIgu,
        portfolioLink: "https://ig_morn.artstation.com",
      },
      {
        name: "Vittor Augusto",
        role: "UX/UI Design",
        photoUrl: fotoVittu,
        portfolioLink: "https://www.linkedin.com/in/vittor-augusto-873b2b214/",
      },
      {
        name: "Rômulo José",
        role: "Programador",
        photoUrl: fotoRombo,
        portfolioLink: "https://www.linkedin.com/in/r%C3%B4mulo-jos%C3%A9-7b3b93167/",
      },
      // Adicione outros membros conforme necessário
    ];
  
    // Ordena os membros pelo nome em ordem alfabética
    const sortedTeamMembers = teamMembers.sort((a, b) => a.name.localeCompare(b.name));
  
    const sections = [
      {
        title: "Nossa Equipe",
        content: "Nós somos um time de desenvolvedores indie apaixonados por jogos. Nosso foco é criar experiências autênticas e originais. Atualmente, estamos trabalhando em dois jogos: o 'Super Duck Madness', um jogo runner 2D em pixel art para Android, com lançamento previsto para o final do ano, e um jogo estilo metroidvania em fase inicial de desenvolvimento que também pertence ao mundo de Super Duck Madness. Junte-se a nós nessa jornada e conheça mais sobre o que nos motiva a criar.",
        component: (
          <div className={styles.teamList}>
            {sortedTeamMembers.map((member, index) => (
              <TeamMember
                key={index}
                name={member.name}
                role={member.role}
                photoUrl={member.photoUrl}
                portfolioLink={member.portfolioLink}
              />
            ))}
          </div>
        ),
      },
      // Adicione mais seções conforme necessário
    ];
  
    return <DefaultPage sections={sections} />;
  }
  
  export default AboutUs;
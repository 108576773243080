// ProjectHighlight.js
import React from "react";
import styles from "./Highlight.module.css";

function Highlight({ title, description, videoUrl, moreLink }) {
  return (
    <div className={styles.projectHighlight}>
      <div className={styles.textContainer}>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className={styles.projectHighlightVideo}>
        {videoUrl && (
          <iframe
            title={title}
            src={videoUrl}
            frameBorder="0"
            allowFullScreen
            className={styles.videoFrame}
          ></iframe>
        )}
      </div>
      {moreLink && (
        <a href={moreLink} className={styles.moreLink}>
          veja mais
        </a>
      )}
    </div>
  );
}

export default Highlight;

// Home.js
import React from "react";
import bannerHome from "./../../assets/images/bannerHome.png";
import Highlight from "../../Components/Highlight";
import DefaultPage from "../../Components/DefaultPage";
import Contact from "../../Components/Contact";

function Home() {
  const sections = [
    {
      title: "Olá!",
      content: "Seja bem-vindo ao universo empolgante dos jogos indie! Aqui, criamos experiências que transcendem o comum. No calor dos códigos e na arte dos pixels, nasce a magia que dá vida aos nossos jogos. Navegue pelo nosso mundo de criatividade e aventura, onde cada linha de código é um passo em direção a novas histórias emocionantes. Prepare-se para se encantar, porque a diversão está prestes a começar",
      moreLink: "",
    },
    {
      title: "Sobre Nós",
      content: "Nós somos um time de desenvolvedores indie apaixonados por jogos. Nosso foco é criar experiências autênticas e originais. Atualmente, estamos trabalhando em dois jogos: o 'Super Duck Madness', um jogo runner 2D em pixel art para Android, com lançamento previsto para o final do ano, e um jogo estilo metroidvania em fase inicial de desenvolvimento que também pertence ao mundo de Super Duck Madness. Junte-se a nós nessa jornada e conheça mais sobre o que nos motiva a criar.",
      moreLink: "/about",
    },
    {
      title: "Destaques",
      content: "",
      component: (
        <Highlight
          title="Super Duck Madness"
          description="Corra, pule e voe em Super Duck Madness, o emocionante jogo runner em pixel art para android."
          videoUrl="https://www.youtube.com/embed/vEesjY61SSU"
          moreLink="/Games"
        />
      ),
    },
    // Adicione a seção de Contatos
    {
      title: "Contate-nos",
      content: "",
      component: <Contact />,
    },
  ];

  return (
    <DefaultPage bannerImage={bannerHome} bannerAlt="Banner Principal" sections={sections} />
  );
}

export default Home;

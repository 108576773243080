// DefaultSection.js
import React from "react";
import { Link } from "react-router-dom";  // Importe o componente Link do React Router
import styles from "./DefaultSection.module.css";

function DefaultSection({ title, content, children, moreLink }) {
  return (
    <section className={styles.defaultSection}>
      <h1 className={styles.title}>{title}</h1>
      {content && <p className={styles.text}>{content}</p>}
      {children}
      {moreLink && (
        <Link to={moreLink} className={styles.moreLink}>
          veja mais
        </Link>
      )}
    </section>
  );
}

export default DefaultSection;

import React from "react";
import styles from "./Banner.module.css";

function Banner({ imageUrl, altText }) {
  return (
    <div className={styles.homeBannerContainer}>
      <img
        className={styles.bannerImage}
        src={imageUrl}
        alt={altText}
      />
    </div>
  );
}

export default Banner;

// Contato.js
import React from "react";
import ContactSection from "../../Components/Contact";
import DefaultPage from "../../Components/DefaultPage";

function ContactUs() {
  const sections = [
    {
      title: "Entre em Contato",
      content: "Ficaremos felizes em ouvir de você. Use os detalhes abaixo para entrar em contato conosco.",
      component: <ContactSection />,
    },
    // Adicione mais seções conforme necessário
  ];

  return <DefaultPage sections={sections} />;
}

export default ContactUs;

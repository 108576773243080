import React from "react";
import styles from "./Footer.module.css";

import linkedinIcon from "./../../assets/images/linkedin-icon.png";
import instagramIcon from "./../../assets/images/instagram-icon.png";
import googlePlayIcon from "./../../assets/images/googlePlay-icon.png";

function Footer() {
  return (
    <footer className={styles.footerContainer}>
      <div className={styles.footerContent}>
        <div className={styles.socialMedia}>
          <nav>
            <ul className={styles.socialMediaImages}>
              <li>
                <a
                  href="https://www.instagram.com/freshchickengames"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className={styles.image}
                    src={instagramIcon}
                    alt="icone do instagram"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/fresh-chicken-games/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className={styles.image}
                    src={linkedinIcon}
                    alt="icone do linkedin"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://play.google.com/store/apps/dev?id=9088742610303405068"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className={styles.image}
                    src={googlePlayIcon}
                    alt="icone do twitter"
                  />
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <p className={styles.footerText}>© 2022 Fresh Chicken Games</p>
        <p className={styles.footerText}>contact@freshchickengames.com.br</p>
      </div>
    </footer>
  );
}

export default Footer;

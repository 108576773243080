import "./App.css";
// Importe as bibliotecas necessárias
import { HashRouter as Router, Route, Routes } from "react-router-dom";

import Header from "./Components/Header";
import Footer from "./Components/Footer";
import AboutUs from "./Pages/AboutUs";
import Games from "./Pages/Games";
import Home from "./Pages/Home";
import ContactUs from "./Pages/ContactUs";

function App() {
  return (
    <Router>
      <div className="AppContent">
        <Header />
        <main>
          {/* Utilize Routes em vez de Switch */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/About" element={<AboutUs />} />
            <Route path="/Games" element={<Games />} />
            <Route path="/contato" element={<ContactUs />} />
            {/* Adicione mais rotas conforme necessário */}
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

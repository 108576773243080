// Header.js

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";  // Importe o componente Link
import styles from "./Header.module.css";
import freshChickenGamesLogo from "./../../assets/images/freshchicken-logo.png";

function Header() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const menuRef = useRef(null);

  useEffect(() => {
    const closeMenuOnOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMobileMenu(false);
      }
    };

    document.addEventListener("click", closeMenuOnOutsideClick);

    return () => {
      document.removeEventListener("click", closeMenuOnOutsideClick);
    };
  }, []);

  const handleMenuIconClick = (event) => {
    event.stopPropagation();
    setShowMobileMenu(!showMobileMenu);
  };

  const handleMenuItemClick = () => {
    // Feche o menu ao clicar em qualquer item do menu
    setShowMobileMenu(false);
  };

  return (
    <header className={styles.headerContainer}>
      <div className={styles.headercontent}>
        <nav>
          <div
            className={`${styles.mobileMenuIcon} ${
              showMobileMenu ? styles.rotateIcon : ""
            }`}
            onClick={handleMenuIconClick}
          >
            <img
              src={freshChickenGamesLogo}
              alt="Ícone do menu"
              className={styles.menuIconImage}
            />
          </div>
          <ul
            className={`${styles.headerMenu} ${
              showMobileMenu ? styles.showMobileMenu : ""
            }`}
            ref={menuRef}
          >
            <li className={styles.logoItem}>
              <img
                className={styles.logoImage}
                src={freshChickenGamesLogo}
                alt="Descrição da imagem"
              />
            </li>
            <li>
              <Link
                className={styles.headerLink}
                to="/"
                onClick={handleMenuItemClick}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                className={styles.headerLink}
                to="/Games"
                onClick={handleMenuItemClick}
              >
                Jogos
              </Link>
            </li>
            <li>
              <Link
                className={styles.headerLink}
                to="/about"
                onClick={handleMenuItemClick}
              >
                Sobre
              </Link>
            </li>
            <li>
              <Link
                className={styles.headerLink}
                to="/contato"
                onClick={handleMenuItemClick}
              >
                Contato
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
// DefaultPage.js
import React from "react";
import styles from "./DefaultPage.module.css";
import DefaultSection from "../../Components/DefaultSection";
import Banner from "../../Components/Banner";
import Contact from "../../Components/Contact";

function DefaultPage({ bannerImage, bannerAlt, sections }) {
  return (
    <div className={styles.pageContainer}>
      <div className={styles.pageContent}>
        {bannerImage && <Banner imageUrl={bannerImage} altText={bannerAlt} />}

        {sections.map((section, index) => (
          <DefaultSection
            key={index}
            title={section.title}
            content={section.content}
            moreLink={section.moreLink}
          >
            {section.component}
          </DefaultSection>
        ))}
      </div>
    </div>
  );
}

export default DefaultPage;

// Games.js
import React from "react";
import styles from "./Games.module.css";
import Project from "../../Components/Project";
import DefaultPage from "../../Components/DefaultPage";

function Games() {
  const gamesList = [
    {
      title: "Super Duck Madness",
      shortDescription: "Corra, pule e voe em Super Duck Madness, o emocionante jogo runner em pixel art para Android.",
      fullDescription: "Super Duck Madness é um jogo envolvente que coloca você no controle de um pato corajoso. Explore mundos pixelizados, supere obstáculos e desafie seus reflexos. Este jogo é a combinação perfeita de diversão e desafio.",
      videoUrl: "https://www.youtube.com/embed/vEesjY61SSU",
    },
    // Adicione outros jogos conforme necessário
  ];

  const sections = [
    {
      title: "Nossos Jogos",
      content: "Confira a lista dos emocionantes jogos desenvolvidos pela nossa equipe.",
      component: (
        <div className={styles.gamesList}>
          {gamesList.map((game, index) => (
            <Project
              key={index}
              title={game.title}
              shortDescription={game.shortDescription}
              fullDescription={game.fullDescription}
              videoUrl={game.videoUrl}
            />
          ))}
        </div>
      ),
    },
    // Adicione mais seções conforme necessário
  ];

  return (
    <DefaultPage sections={sections} />
  );
}

export default Games;

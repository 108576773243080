// Project.js
import React from "react";
import styles from "./Project.module.css";

function Project({ title, subTitle, shortDescription, fullDescription, imageUrl, videoUrl }) {
  return (
    <div className={styles.project}>
      <div className={styles.textContainer}>
        <h2 className={styles.projectTitle}>{title}</h2>
        {subTitle && <h3 className={styles.subTitle}>{subTitle}</h3>}
        <p className={styles.shortDescription}>{shortDescription}</p>
      </div>
      <div className={styles.projectImage}>
        {imageUrl && <img src={imageUrl} alt={title} className={styles.image} />}
      </div>
      <div className={styles.projectVideo}>
        {videoUrl && (
          <iframe
            title={title}
            src={videoUrl}
            frameBorder="0"
            allowFullScreen
            className={styles.videoFrame}
          ></iframe>
        )}
        {videoUrl && <div className={styles.videoSpace}></div>}
      </div>
      <p className={styles.fullDescription}>{fullDescription}</p>
    </div>
  );
}

export default Project;

// TeamMember.js
import React from "react";
import styles from "./TeamMember.module.css";

function TeamMember({ name, role, photoUrl, portfolioLink }) {
  return (
    <div className={styles.teamMember}>
      <img src={photoUrl} alt={name} className={styles.memberPhoto} />
      <div className={styles.memberInfo}>
        <h2 className={styles.memberName}>{name}</h2>
        <p className={styles.memberRole}>{role}</p>
        {portfolioLink && (
          <a href={portfolioLink} className={styles.portfolioLink} target="_blank" rel="noopener noreferrer">
            Ver Portfólio
          </a>
        )}
      </div>
    </div>
  );
}

export default TeamMember;

// ContactSection.js
import React from "react";
import styles from "./Contact.module.css";

function Contact() {
  return (
    <section className={styles.defaultSection}>
      <form action="/submit-form" method="post">
        <div className={styles.contactForm}>
          <div className={styles.contactFormInput}>
            <label className={styles.contactLabel} htmlFor="name">
              Nome
            </label>
            <input type="text" id="name" name="name" />
          </div>
          <div className={styles.contactFormInput}>
            <label className={styles.contactLabel} htmlFor="email">
              E-mail
            </label>
            <input type="email" id="email" name="email" />
          </div>
          <div className={styles.contactFormInput}>
            <label className={styles.contactLabel} htmlFor="message">
              Mensagem
            </label>
            <textarea id="message" name="message"></textarea>
          </div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </section>
  );
}

export default Contact;
